<template>
  <div class="page-rate-pay">
    <div class="banner">
      <img src="../assets/images/nashuiketang.jpg" alt="" width="100%">
    </div>
    <div class="raw-context">
      <el-row class="row-1">
        <el-col :span="4"><div class="label">关键字:</div></el-col>
        <el-col :span="16">
          <div class="search-input">
            <el-input placeholder="请输入关键词" suffix-icon="el-icon-search" v-model="queryParams.searchStr" @keyup.enter.native="search"/>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" class="search-btn" @click="search">搜索</el-button>
        </el-col>
      </el-row>
      <div class="filter-wrap">
        <div class="row row-1">
          <div :class="['all', {active: parentId == ''}]" @click="onAllClick('parentId')">全部</div>
          <div class="list">
            <div :class="['item', {active: parentId == item.id}]" v-for="item in Options_1" :key="item.id"
                 @click="chooseItem(item, 'parentId')">{{item.name}}</div>
          </div>
        </div>
        <div class="row row-2" v-if="Options_2.length">
          <div :class="['all', {active: currentId == ''}]" @click="onAllClick('currentId')">全部</div>
          <div class="list">
            <div :class="['item', {active: currentId == item.id}]" v-for="(item, n) in Options_2" :key="n"
                 @click="chooseItem(item, 'currentId')">{{item.name}}</div>
          </div>
        </div>
        <div class="row row-3" v-if="Options_3.length">
          <div :class="['all', {active: sonId == ''}]" @click="onAllClick('sonId')">全部</div>
          <div class="list">
            <div :class="['item', {active: sonId == item.id}]" v-for="(item, n) in Options_3" :key="n"
                 @click="chooseItem(item, 'sonId')">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="content" v-loading="loading">
        <div class="row-wrap" v-if="dataList.length">
          <div class="row-list-item" v-for="(item, n) in dataList" :key="n" @click="toDetail(item)">
            <div class="picture"><img :src="item.cover" alt="" width="100%" border="0"></div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
        <el-empty :image-size="100" v-else></el-empty>
      </div>

      <div class="row-page">
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="pageChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ratePayCourse',
  data () {
    return {
      loading: false,
      Options_1: [],
      Options_2: [],
      Options_3: [],
      parentId: '',
      currentId: '',
      sonId: '',
      dataList: [],
      total: 0,
      queryParams: {
        pageSize: 12,
        pageNum: 1,
        searchStr: '',
        categoryId: ''
      }
    }
  },
  methods: {
    pageChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    },
    chooseItem (item, key) {
      if (this[key] === item.id) return
      this[key] = item.id
      if (key === 'parentId') {
        this.currentId = ''
        this.sonId = ''
        this.Options_3 = []
        this.init(item.code, 'Options_2')
      } else if (key === 'currentId') {
        this.sonId = ''
        this.init(item.code, 'Options_3')
      } else if (key === 'sonId') {

      }
      this.queryParams.categoryId = item.id
      this.search()
    },
    init (code, setKey) {
      this.API.getTaxCategory({ code }).then(({ code, data }) => {
        this[setKey] = data
      })
    },
    getList () {
      this.loading = true
      this.API.getClassSearchPage(this.queryParams).then(({ code, data }) => {
        this.dataList = data.list
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    onAllClick (key) {
      if (key === 'parentId') {
        this.parentId = ''
        this.currentId = ''
        this.Options_2 = []
        this.sonId = ''
        this.Options_3 = []
        this.queryParams.categoryId = ''
      } else if (key === 'currentId') {
        this.currentId = ''
        this.sonId = ''
        this.Options_3 = []
        this.queryParams.categoryId = this.parentId
      } else if (key === 'sonId') {
        this.sonId = ''
        this.queryParams.categoryId = this.currentId
      }
      this.search()
    },
    search () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    toDetail (item) {
      // this.$router.push(`/ratePayCourseDetail/${item.id}`)
      window.open(`/#/ratePayCourseDetail/${item.id}`)
    }
  },
  created () {
    this.init('NSKT', 'Options_1')
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.page-rate-pay{
  background: #ffffff;
  text-align: left;
  .raw-context{
    width: 1200px;
    margin: 30px auto 0;
    .row-1{
      line-height: 40px;
      .label{
        text-align: right;
        margin-right: 14px;
      }
      .search-btn{
        margin-left: 14px;
      }
    }
  }

  .filter-wrap .row{
    display: flex;
    text-align: center;
    margin: 30px 0;
    line-height: 40px;
    .all{
      width: 100px;
      height: 40px;
      border: 1px solid #ddd;
      cursor: pointer;
      &.active{
        background: #20a0ff;
        color: #fff;
        border-color: #20a0ff;
      }
    }
    .list{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item{
        border: 1px solid #ddd;
        margin: 0 0 10px 30px;
        font-size: 14px;
        padding: 0 20px;
        cursor: pointer;
        min-width: 60px;
        &.active{
          background: #20a0ff;
          color: #fff;
          border-color: #20a0ff;
        }
      }
    }
  }
  .content{
    width: 1200px;
    min-height: 225px;
    .row-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      .row-list-item{
        width: 350px;
        margin: 10px 25px 20px;
        .picture{
          height: 200px;
          background: #ebf5fa;
          border: 1px solid #ddd;
          border-bottom: none;
          img{
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
        .text{
          border: 1px solid #ddd;
          line-height: 44px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .row-page{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  img:not([src]) {
    opacity: 0;
  }
}
</style>
